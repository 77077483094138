import '../../../shared/js/utils/polyfills';
import { createDebouncedFunc } from '../../../shared/js/utils/global-helpers';

let lastScrollTop = 0;
let nav = null;
let navHeight = 0;

export function initStickyMenu() {
    nav = document.getElementById('page-header');
    navHeight = nav.offsetHeight;

    nav.style['height'] = navHeight + 'px';
    nav.style.setProperty('--nav-height', navHeight + 'px');

    stickyNav();
    bindScrollFunctions();

    function stickyNav() {
        const navHeightSmall = nav.offsetHeight;

        if (window.scrollY > navHeight + 50) {
            nav.classList.add('sticky');
        } else if (window.scrollY < navHeightSmall + 50) {
            nav.classList.remove('sticky');
        }
    }

    window.addEventListener('scroll', stickyNav);
    window.addEventListener(
        'resize',
        createDebouncedFunc(() => {
            nav.style['height'] = null;
            nav.classList.remove('sticky');
            nav.style['height'] = nav.offsetHeight + 'px';
            nav.style.setProperty('--nav-height', nav.offsetHeight + 'px');

            stickyNav();
            bindScrollFunctions();
        }, 50)
    );
}

const bindScrollFunctions = () => {
    window.removeEventListener('scroll', processMobileScroll);

    // Mobil
    if (!window.matchMedia('(min-width: 576px)').matches) {
        window.addEventListener('scroll', processMobileScroll);
    }
};

const processMobileScroll = () => {
    const positionY = window.scrollY;
    const diff = positionY - lastScrollTop;
    const delta = 10;

    if (Math.abs(diff) <= delta) {
        return;
    }

    lastScrollTop = positionY;

    if (diff > 0) {
        nav.classList.remove('mobile-sticky--expanded');

        // Pokud jsme dal, nez vyska hlavicky, zpozdime o trvani animace
        if (positionY > navHeight) {
            setTimeout(() => {
                nav.classList.remove('mobile-sticky');
            }, 200);
        } else {
            nav.classList.remove('mobile-sticky');
        }
    } else {
        // Sticky hlavicka jen, pokud jsme strankou za vyskou hlavicky
        if (positionY > navHeight) {
            nav.classList.add('mobile-sticky', 'mobile-sticky--expanded');
        }
    }
};
